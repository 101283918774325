import React from 'react'
import styled from 'styled-components'
import { media } from 'utils/Media'

const LegalBodyStyles = styled.div`
  width: 100%;
  section {
    max-width: 750px;
    width: 90%;
    margin: 2rem auto;
    color: var(--white);
    p,
    li,
    h5,
    h4 {
      color: var(--white);
    }
    p {
      margin-bottom: 0.5rem;
    }
    li {
      margin-bottom: 0.25rem;
    }
    ul {
      margin-left: 16px;
      list-style: var(--white);
      @media ${media.md} {
        margin-left: 1.5px;
      }
    }
    h5 {
      margin: 1rem 0;
      font-weight: 800;
      line-height: 1.5;
    }
  }
`
const LegalBody = ({ body }) => {
  console.log(body)
  return (
    <LegalBodyStyles>
      <section dangerouslySetInnerHTML={{ __html: body }} />
    </LegalBodyStyles>
  )
}

export default LegalBody
