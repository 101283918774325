import Footer from 'components/Footer'
import Layout from 'components/Layout'
import { LegalBody } from 'components/Legal'
import { LegalHeader } from 'components/Legal/LegalHeader'
import { graphql } from 'gatsby'

import React from 'react'

const LegalPageTemplate = ({ data }) => {
  const { page } = data
  return (
    <Layout>
      <LegalHeader title={page.title} />
      <LegalBody body={page.body} />
      <Footer $legal />
    </Layout>
  )
}

export default LegalPageTemplate

export const LegalQuery = graphql`
  query LegalQuery($slug: String) {
    page: legalPagesJson(slug: { eq: $slug }) {
      id
      body
      title
    }
  }
`
