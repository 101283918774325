import React from 'react'
import styled from 'styled-components'

const LegalHeaderStyles = styled.header`
  width: 100%;
  section {
    padding: 3rem 0;
    max-width: 750px;
    width: 90%;
    margin: var(--auto);
    border-bottom: solid 3px var(--white);
    h1 {
      color: var(--white);
      font-weight: 800;
      text-align: center;
    }
  }
`
const LegalHeader = ({ title }) => (
  <LegalHeaderStyles>
    <section>
      <h1>{title}</h1>
    </section>
  </LegalHeaderStyles>
)

export default LegalHeader
